'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.locations.factory:CountryManager

 # @description

###
angular
  .module 'mundoAdmin.locations'
  .factory 'CountryManager', [
    'MundoDefaultManager'
    '$q'
    'Restangular'
    '$log'
    '_'
    (
      MundoDefaultManager
      $q
      Restangular
      $log
      _
    )->
      CountryManagerBase = new MundoDefaultManager()
      CountryManagerBase.setUrl('countries')

      CountryManagerBase.getListCustom = ->
        countries = []

        Restangular.all(@conf.url).customGET('').then((data) ->
          for k, v of data.results
            countries.push({
              code: k
              value: v
            })
          countries
        )
        countries

      CountryManagerBase.getFullList = ->
        response = $q.defer()
        response.resolve @getListCustom()
        response.promise

      CountryManagerBase
  ]
